











import { Vue, Component } from 'vue-property-decorator'
import __ from '@/shared/helpers/__'
import FormBase from '@/shared/classes/form/form-base'
import { Positions } from '@/shared/interfaces/classes/form.interfaces'
import Field from '@/shared/classes/form/field'
import { FieldTypes } from '@/shared/components/form/field-types'
import Form from '@/shared/components/form/Form.vue'
import { AuthRoutes } from '@/shared/router/auth'
import AuthLayout from '@/company/layouts/AuthLayout.vue'

@Component({
  components: {
    AuthLayout,
    Form
  },
  methods: {
    __
  }
})
export default class RemindPassword extends Vue {
  message: string | null = null
  form: FormBase|null = null

  created() {
    this.form = new FormBase()
      .setEndpoint('password/send')
      .setSubmit({
        text: __('company.views.remind-password.form.submit'),
        position: Positions.center,
        class: 'auth-submit',
      })
      .setFields([
        new Field()
          .setType(FieldTypes.email)
          .isUnderlined()
          .setKey('email')
          .setTitle(__('company.views.remind-password.form.email'))
      ])
      .setInjectValues({
        url: this.$router.resolve({
          name: AuthRoutes.resetPassword,
          params: {
            token: ':token',
            email: ':email'
          }
        }).href
      })
      .setOnSuccess(this.onSuccess)
  }

  onSuccess(response: any) {
    this.message = response.message
  }

  goToLogin(): void {
    this.$router.push({ name: AuthRoutes.login })
  }
}
